<template>
  <div class="Product">
    <v-container fluid class="py-0">
      <v-row>
        <v-col class="Product__div-cream pa-0" cols="12">
          <v-row justify="center" class="Product__div-cream--row-product">
            <v-col
              cols="12"
              md="10"
              class="Product__div-cream--row-product__column-margin"
            >
              <v-row justify="center">
                <v-col cols="auto" sm="6" md="auto">
                  <v-carousel
                    v-model="carouselProduct"
                    v-bind:height="heightCarouselProduct"
                    v-bind:show-arrows="arrowsMobile"
                    interval="3000"
                    hide-delimiters
                    cycle
                    light
                    class="Product__div-cream--row-product__carousel"
                  >
                    <div
                      class="div-dots-product position-relative d-none d-sm-flex"
                    >
                      <div
                        v-for="(dot, dt) in product.product_images"
                        v-bind:key="dt"
                        v-bind:class="{ 'active-dot': dt == carouselProduct }"
                        @click="carouselProduct = dt"
                        class="div-dots-product__dot"
                      ></div>
                    </div>

                    <template v-for="(image, img) in product.product_images">
                      <template v-if="image.value.image != null">
                        <v-carousel-item :key="img">
                          <v-img
                            :src="
                              'https://www.cymcms.actstudio.xyz' +
                              image.value.image.path
                            "
                            contain
                            class="Product__div-cream--row-product__carousel__image"
                          ></v-img>
                        </v-carousel-item>
                      </template>
                    </template>
                  </v-carousel>
                </v-col>
                <v-col cols="auto" sm="6" md="auto">
                  <v-row no-gutters>
                    <v-col cols="12">
                      <!-- nombre y numero de referencia md para arriba -->
                      <v-row align="center" no-gutters class="d-none d-sm-flex">
                        <v-col cols="auto">
                          <span
                            class="titles-extra-big"
                            v-bind:class="{
                              'bree-serif':
                                product.style.name == 'Transitional',
                              pacifico: product.style.name == 'Classic',
                              'montserrat-regular': product.style.name == 'Modern',
                            }"
                            >{{ product.name }}</span
                          >
                        </v-col>
                      </v-row>
                      <!-- /nombre y numero de referencia md para arriba -->

                      <!-- materiales md para arriba-->
                      <div class="d-sm-none mb-10">
                        <v-row>
                          <v-col class="text-center">
                            <span
                              class="d-block bree-serif bold Product__div-cream--row-product__materials pb-3"
                              >Materials</span
                            >
                          </v-col>
                        </v-row>
                        <v-row no-gutters>
                          <v-col cols="4" class="px-0">
                            <img
                              src="@/assets/icons/construction-product-cym.png"
                              width="37"
                              class="d-block mb-2"
                            />
                            <span
                              class="d-block montserrat-regular continuos-texts-small"
                              >Construction</span
                            >
                            <span
                              class="bold montserrat-regular continuos-texts-small line-height-some-titles-2 mt-2"
                              >{{ product.constructionDescription }}</span
                            >
                          </v-col>

                          <v-col cols="4" class="px-3">
                            <img
                              src="@/assets/icons/fabric-product-cym.png"
                              width="37"
                              class="d-block mb-2"
                            />

                            <span
                              class="d-block montserrat-regular continuos-texts-small"
                              >Fabric</span
                            >
                            <span
                              class="bold montserrat-regular continuos-texts-small line-height-some-titles-2 mt-2"
                              >{{ product.fabricDescription }}</span
                            >
                          </v-col>

                          <v-col cols="4" class="px-0">
                            <img
                              src="@/assets/icons/paint-product-cym.png"
                              width="37"
                              class="d-block mb-2"
                            />
                            <span
                              class="d-block montserrat-regular continuos-texts-small"
                              >Paint</span
                            >
                            <span
                              class="d-block bold montserrat-regular continuos-texts-small line-height-some-titles-2 mt-2"
                              >{{ product.paintDescription }}</span
                            >
                          </v-col>
                        </v-row>
                      </div>
                      <!-- /materiales md para arriba-->

                      <p class="Product__description">
                        {{ product.description }}
                      </p>

                      <!-- materiales md para arriba-->
                      <div class="d-none d-sm-block">
                        <span
                          class="d-block bree-serif bold Product__div-cream--row-product__materials text-center pb-8"
                          >Materials</span
                        >

                        <v-row no-gutters>
                          <v-col
                            cols="4"
                            class="pr-3 Product__div-cream--row-product__materials--content"
                            v-show="product.constructionDescription"
                          >
                            <img
                              src="@/assets/icons/construction-product-cym.png"
                              width="55"
                              class="d-block mb-2"
                            />
                            <span
                              class="d-block montserrat-regular continuos-texts-small"
                              >Construction</span
                            >
                            <span
                              class="d-block bold montserrat-regular continuos-texts-small mt-2"
                              >{{ product.constructionDescription }}</span
                            >
                          </v-col>

                          <v-col
                            cols="4"
                            class="px-3 Product__div-cream--row-product__materials--content"
                            v-show="product.fabricDescription"
                          >
                            <img
                              src="@/assets/icons/fabric-product-cym.png"
                              width="55"
                              class="d-block mb-2"
                            />

                            <span
                              class="d-block montserrat-regular continuos-texts-small"
                              >Fabric</span
                            >
                            <span
                              class="d-block bold montserrat-regular continuos-texts-small mt-2"
                              >{{ product.fabricDescription }}</span
                            >
                          </v-col>

                          <v-col
                            cols="4"
                            class="pl-3 Product__div-cream--row-product__materials--content"
                            v-show="product.paintDescription"
                          >
                            <img
                              src="@/assets/icons/paint-product-cym.png"
                              width="55"
                              class="d-block mb-2"
                            />
                            <span
                              class="d-block montserrat-regular continuos-texts-small"
                              >Paint</span
                            >
                            <span
                              class="d-block bold montserrat-regular continuos-texts-small mt-2"
                              >{{ product.paintDescription }}</span
                            >
                          </v-col>
                        </v-row>
                      </div>
                      <!-- /materiales md para arriba-->
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!-- features & benefits -->
      <v-row class="Product__row-white" justify="center" justify-sm="end">
        <v-col cols="11" class="Product__row-white__div-blue pa-sm-0">
          <v-row
            align-sm="center"
            justify="center"
            class="Product__row-white__div-blue--row-inside"
          >
            <v-col
              cols="auto"
              sm="7"
              md="auto"
              class="position-relative py-0 py-md-3 Product__row-white__div-blue--div-carousel"
            >
              <!-- arrows -->

              <div
                class="arrow-right in-product d-none d-sm-block"
                @click="carouselProductFB++"
              >
                <v-img src="@/assets/icons/arrow-slider.svg"></v-img>
              </div>

              <div
                class="arrow-left in-product d-none d-sm-block"
                @click="preview()"
              >
                <v-img src="@/assets/icons/arrow-slider.svg"></v-img>
              </div>
              <!-- /arrows -->
              <v-carousel
                v-model="carouselProductFB"
                v-bind:height="heightCarouselFB"
                v-bind:show-arrows="false"
                interval="3000"
                class="Product__row-white__div-blue__carousel"
                hide-delimiters
              >
                <v-carousel-item
                  v-for="(image, imgs) in product.features_benefits_images"
                  v-bind:key="imgs"
                >
                  <template v-if="image != null">
                    <v-img
                      :src="'https://www.cymcms.actstudio.xyz/' + image.path"
                    ></v-img>
                  </template>
                </v-carousel-item>
              </v-carousel>
            </v-col>
            <v-col cols="auto" sm="5" md="auto" class="Product__column-fb">
              <v-row
                class="Product__row-white__div-blue--row-inside-2"
                justify="center"
              >
                <v-col cols="auto" md="12" class="px-8 line-height-1">
                  <div>
                    <span class="titles-extra-big color-cream bree-serif"
                      >Features <br />
                      & Benefits</span
                    >
                  </div>
                </v-col>
                <v-col cols="12">
                  <span
                    class="d-block color-cream Product__row-white__div-blue--row-inside-2__list-fb continuos-texts-base"
                    v-for="(itemList, it) in product.features_benefits_content"
                    v-bind:key="it"
                    >{{ itemList.value }}</span
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- /features & benefits -->

      <!-- barstool -->
      <v-row justify="center" class="pt-16 pt-sm-1">
        <v-col cols="12" md="10">
          <v-row align="center">
            <v-col cols="12" md="4">
              <div class="line-height-1 Product__barstool-title">
                <span class="bree-serif bold titles-extra-big"
                  >Barstool Dimensions</span
                >
              </div>
              <div class="mt-6 Product__barstool-description">
                <p>
                  {{ product.barstool_description }}
                </p>
              </div>
            </v-col>

            <v-col cols="12" md="8">
              <v-img
                :src="
                  'https://www.cymcms.actstudio.xyz/' +
                  product.barstool_image.path
                "
              ></v-img>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- /barstool -->

      <!-- reviews -->
      <v-row
        class="mt-12 mb-md-12"
        id="Reviews"
        v-if="product.review_linked != ''"
      >
        <v-col class="text-center">
          <span class="titles-big">Reviews</span>
        </v-col>
        <v-col cols="12" class="px-0">
          <v-slide-group
            v-model="slideReviews"
            v-bind:show-arrows="false"
            center-active
            mandatory
          >
            <v-slide-item
              v-for="(review, rw) in product.review_linked"
              v-bind:key="rw"
              v-slot="{ active, toggle }"
            >
              <v-card
                class="Product__card-reviews"
                @click="toggle"
                v-bind:ripple="false"
                v-bind:class="active ? undefined : 'inactive'"
                elevation="0"
                @mouseover="isPaused = true"
                @mouseleave="isPaused = false"
              >
                <v-row justify="center" align="center" style="height: 100%">
                  <v-col cols="auto">
                    <v-card
                      class="Product__card-reviews--card-rating"
                      elevation="0"
                    >
                      <v-row
                        align="center"
                        justify="center"
                        no-gutters
                        style="height: 100%"
                      >
                        <v-col cols="auto" class="text-center">
                          <span class="d-block"
                            >{{ review.stars }} star<template
                              v-if="review.stars > 1"
                              >s</template
                            ></span
                          >

                          <v-img
                            src="@/assets/icons/star.svg"
                            class="d-inline-block mt-2 mr-2"
                            v-for="(star, s) in review.stars"
                            v-bind:key="s"
                          ></v-img>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>

                  <v-col cols="auto">
                    <div class="Product__card-reviews--column-right">
                      <span class="continuos-texts-xxs-small review-date">{{
                        review.date
                      }}</span>
                      <span class="continuos-texts-2 d-block">{{
                        review.comment
                      }}</span>
                      <span class="continuos-texts-small d-block">{{
                        review.comment_detail
                      }}</span>

                      <span class="d-inline-block mt-8"
                        >By {{ review.written_by }}</span
                      >
                      <span
                        class="d-inline-block continuos-texts-xxs-small ml-2"
                        >{{ review.verified }}</span
                      >
                      <span class="d-block">From {{ review.from }}</span>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-slide-item>
          </v-slide-group>
        </v-col>
        <v-col class="d-sm-none position-relative pt-16" cols="12">
          <div class="div-dots-reviews">
            <div
              v-for="(dot, dt) in product.review_linked"
              v-bind:key="dt"
              v-bind:class="{ 'active-dot': dt == slideReviews }"
              @click="slideReviews = dt"
              class="div-dots-reviews__dot"
            ></div>
          </div>
        </v-col>
      </v-row>
      <!-- /reviews -->

      <!-- entregas -->
      <v-row justify="center" class="d-none">
        <v-col cols="10">
          <v-row justify="center" align="center" no-gutters>
            <v-col cols="auto">
              <v-card
                class="Product__deliveries--card text-center border-right-deliveries"
                tile
                elevation="0"
              >
                <v-row style="height: 100%" align="center">
                  <v-col cols="12" class="pb-0">
                    <v-img
                      src="@/assets/icons/delivery.svg"
                      width="67"
                      class="d-block mx-auto"
                    ></v-img>
                  </v-col>
                  <v-col cols="12" class="pt-0">
                    <v-card-text class="pa-0">
                      <span class="d-block">Entrega</span>
                      <span class="d-block">De 3 a 5 días en México</span>
                      <span class="d-block">De 5 a 10 días en otro país</span>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="auto">
              <v-card
                class="Product__deliveries--card text-center border-right-deliveries"
                tile
                elevation="0"
              >
                <v-row style="height: 100%" align="center">
                  <v-col cols="12" class="pb-0">
                    <v-img
                      src="@/assets/icons/list.svg"
                      width="42"
                      class="d-block mx-auto"
                    ></v-img>
                  </v-col>
                  <v-col cols="12" class="pt-0">
                    <v-card-text class="pa-0">
                      <span class="d-block">Garantía</span>
                      <span class="d-block">6 meses por defecto</span>
                      <span class="d-block">de fabrica</span>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="auto">
              <v-card
                class="Product__deliveries--card text-center"
                tile
                elevation="0"
              >
                <v-row style="height: 100%" align="center">
                  <v-col cols="12" class="pb-0">
                    <v-img
                      src="@/assets/icons/map.svg"
                      width="60"
                      class="d-block mx-auto"
                    ></v-img>
                  </v-col>
                  <v-col cols="12" class="pt-0">
                    <v-card-text class="pa-0">
                      <span class="d-block">Wehavedistributed</span>
                      <span class="d-block">throug hour warehouse</span>
                      <span class="d-block">partnersin Laredo, TX .</span>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- /entregas -->

      <!-- similares -->
      <v-row justify="center" class="mt-md-12">
        <v-col class="text-center" cols="12">
          <span class="titles-big">Similiar Barstools</span>
        </v-col>
        <v-col cols="10">
          <v-row justify="center">
            <template v-for="(product, re) in relatedProducts">
              <template v-if="re < 3">

              

            <v-col
              cols="auto"
              
              v-bind:key="re"
            >
              <v-card
                elevation="0"
                class="Product__card-similars"
                :to="'/product/' + product.url"
              >
                <v-row no-gutters>
                  <v-col class="text-center">
                    <div class="py-5">
                      <v-img
                        class="d-block mx-auto Product__card-similars__image"
                        contain
                        :src="
                          'https://www.cymcms.actstudio.xyz/' +
                          product.cover.path
                        "
                      ></v-img>
                    </div>
                    <v-divider class="mx-12 my-3 divider-product"></v-divider>
                    <v-card-text>
                      <span
                        class="titles-big"
                        v-bind:class="{
                          'bree-serif':
                            product.style.name == 'Transitional',
                          pacifico: product.style.name == 'Classic',
                          'montserrat-regular': product.style.name == 'Modern',
                        }"
                        >{{ product.name }}</span
                      >
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            </template>
            </template>
          </v-row>
        </v-col>
      </v-row>
      <!-- /similares -->
    </v-container>
  </div>
</template>
<script>
export default {
  computed: {
    relatedProducts: function () {
      var allProducts = this.products;

      // var relatedProducts = this;

      var prod= allProducts.filter((related) => related.style.name == this.product.style.name);
      prod= prod.filter((related)=> related._id!= this.product._id);
      prod= prod.sort(function (a, b) { return 0.5 - Math.random() }) 

      return prod;
      // return allProducts;
    },
  },
  data() {
    return {
      product: {
        style:{},
        barstool_image:{
          path:'',
        },
      },
      products: [],
      carouselProduct: 0,
      heightCarouselProduct: 500,
      carouselProductFB: 0,
      heightCarouselFB: 486,
      slideReviews: 1,
      arrowsMobile: false,
      isPaused: false,
    };
  },
  watch: {
    $route(to, from) {
      //fetch para el producto
      fetch(
        "https://www.cymcms.actstudio.xyz/api/collections/get/products?token=aa5f1f62f706c89e9dae0ea5e2400a",
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            filter: {
              url: this.$route.params.product,
            },
            populate: 2,
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => (this.product = res.entries[0]));
    },
  },
  mounted() {
    //resize del producto

    window.addEventListener("resize", this.onResize, { passive: true });

    setInterval(() => {
      if (!this.isPaused) {
        this.slideReviews++;
      }
    }, 3000);

    //fetch para el producto
    
    //emite el valor para cambiar el valor del logo
    this.$emit("update", 0);
  },
  beforeCreate(){

    fetch(
      "https://www.cymcms.actstudio.xyz/api/collections/get/products?token=aa5f1f62f706c89e9dae0ea5e2400a",
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          filter: {
            url: this.$route.params.product,
          },
          populate: 2,
        }),
      }
    )
      .then((res) => res.json())

      .then((res) => (this.product = res.entries[0]));


    fetch(
      "https://www.cymcms.actstudio.xyz/api/collections/get/products?token=aa5f1f62f706c89e9dae0ea5e2400a",
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          filter: {
            
          },
          populate: 2,
          
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => (this.products = res.entries));

  },
  
  beforeDestroy() {
    if (typeof window !== undefined) {
      window.addEventListener("resize", this.onResize, { passive: true });
    }
  },
  methods: {
    onResize: function (e) {
      if (window.innerWidth <= 599) {
        this.heightCarouselProduct = 299;

        this.arrowsMobile = true;
      } else if (window.innerWidth >= 600) {
        this.arrowsMobile = false;
        // this.heightCarouselProduct = 466;
        this.heightCarouselProduct = 500;
      }

      if (window.innerWidth <= 599) {
        this.heightCarouselFB = 310;
      } else if (window.innerWidth >= 600) {
        this.heightCarouselFB = 486;
      }
    },
    preview: function () {
      this.carouselProductFB--;

      if (this.carouselProductFB < 0) {
        this.carouselProductFB =
          this.product.features_benefits_images.length - 1;
      }
    },
  },
};
</script>